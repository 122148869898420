@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-toastify/dist/ReactToastify.css";
@import "./styles/builder.css";
@import "./styles/inputs.css";
@import "./styles/post.css";

@layer base {
  body {
    @apply font-poppins text-text bg-background;
  }

  .text_4xl {
    @apply text-4xl font-normal;
  }
  .text_3xl {
    @apply text-3xl font-semibold;
  }
  .text_2xl {
    @apply text-2xl font-medium;
  }
  .text_xl {
    @apply text-xl font-medium;
  }
  .text_lg {
    @apply text-lg font-medium;
  }
  .text_base {
    @apply text-base font-normal;
  }
  .text_sm {
    @apply text-sm font-medium;
  }
  .text_xs {
    @apply text-xs font-medium text-label;
  }
}

@layer components {
  /* Container */
  .custom-container {
    @apply container px-6 mx-auto md:px-10 lg:px-20 2xl:px-16
  }

  /* Buttons */
  button, button:focus { @apply ring-offset-transparent }

  .btn-base {
    @apply inline-flex items-center rounded-md border border-transparent px-6 py-[10px] text-sm font-medium focus:outline-none gap-2 transition-colors duration-200 ease-in;
  }
  .btn-sm {
    @apply inline-flex items-center rounded-md border border-transparent px-3 py-2 text-xs font-medium focus:outline-none gap-1 transition-colors duration-200 ease-in;
  }

  .btn-primary {
    @apply btn-base text-white bg-primary-500 hover:bg-primary-900 disabled:bg-gray-200 disabled:cursor-not-allowed;
  }
  .btn-primary-alternative {
    @apply btn-base text-primary-700 bg-primary-100 hover:bg-primary-200;
  }
  .btn-primary-outline {
    @apply btn-base text-primary-500 border border-primary-500 hover:bg-primary-50;
  }
  .btn-sm-primary {
    @apply btn-sm text-white bg-primary-500 hover:bg-primary-900 disabled:bg-gray-200 disabled:cursor-not-allowed;
  }
  .btn-sm-primary-outline {
    @apply btn-sm text-primary-500 border border-primary-500 hover:bg-primary-50;
  }

  .btn-secondary {
    @apply btn-base text-white bg-secondary-500 hover:bg-secondary-900;
  }
  .btn-secondary-alternative {
    @apply btn-base text-secondary-700 bg-secondary-100 hover:bg-secondary-200;
  }
  .btn-secondary-outline {
    @apply btn-base text-secondary-500 border border-secondary-500 hover:bg-secondary-50;
  }

  .btn-danger-outline {
    @apply btn-base text-red-500 border border-red-500 hover:bg-red-50;
  }
  .btn-sm-danger-outline {
    @apply btn-sm text-red-500 border border-red-500 hover:bg-red-50;
  }

  .btn-cancel {
    @apply btn-base text-label bg-primary-50 hover:bg-label hover:text-white;
  }

  .btn-delete {
    @apply btn-base text-white bg-red-500 hover:bg-red-900 disabled:bg-gray-200 disabled:cursor-not-allowed;
  }

  .btn-delete.inverse {
    @apply text-red-500 bg-white hover:bg-red-500 hover:text-white disabled:bg-gray-200 disabled:cursor-not-allowed;
  }

  .btn-icon {
    @apply flex items-center justify-center w-8 h-8 aspect-square rounded-full transition-colors duration-200 ease-in text-label hover:bg-blue-50 outline-none
  }

  .btn-icon.cancel {
    @apply hover:bg-red-100
  }
  .btn-icon.good {
    @apply hover:bg-green-100
  }

  /* Badges */
  .badge {
    @apply inline-flex items-center rounded-md px-3 py-1 text-xs font-medium;
  }

  /* Headless UI */

  /* Menu */
  .menu {
    @apply relative inline-block text-left;
  }
  .menu-items {
    @apply absolute z-10 right-0 w-48 mt-2 origin-top-right bg-white divide-y rounded-md shadow-xl divide-primary-50 ring-1 ring-text ring-opacity-5 focus:outline-none;
  }
  .menu-items-wrapper {
    @apply flex flex-col gap-1 px-1 py-1;
  }
  .menu-item {
    @apply flex gap-2 w-full items-center rounded-md px-2 py-2 text-sm main-transition;
  }

  /* Tables */
  .listTable tbody tr {
    @apply relative;
  }
  .listTable tbody tr td {
    @apply relative py-4 px-6 last:text-right;
  }
  .listTable tbody tr.item-highlight td {
    @apply animate-pulse bg-green-400 first:rounded-tl-lg last:rounded-tr-lg;
  }
  .listTable thead {
    @apply bg-transparent
  }
  .listTable thead tr:first-child th {
    @apply bg-gray-200 first:rounded-l-lg last:rounded-r-lg;
  }
  .listTable tbody tr td {
    @apply bg-white;
  }
  .listTable tbody tr {
    @apply border-b last:border-b-0;
  }
  .listTable tbody tr:first-child td  {
    @apply first:rounded-tl-lg last:rounded-tr-lg;
  }
  .listTable tbody tr:last-child td  {
    @apply first:rounded-bl-lg last:rounded-br-lg;
  }


  /* Loader Full Page */
  .loader-full-page {
    @apply w-full h-full flex items-center justify-center
  }
  .loader-styled {
    @apply w-[64px] h-[64px] relative 
    before:content-[''] before:absolute before:left-0 before:bottom-0 before:bg-[#0056EE] before:w-[64px] before:h-[32px] before:rounded-b-[50px]
    after:content-[''] after:absolute after:left-[50%] after:top-[10%] after:bg-[#000F38] after:w-[8px] after:h-[64px];  
    animation: loader-rotate 1.5s ease-in infinite alternate;
  }
  .loader-styled::after {
    animation: loader-rotate 1.2s linear infinite alternate-reverse;
  }
  @keyframes loader-rotate {
    100% { transform: rotate(360deg)}
  }
}

@layer utilities {
  .main-transition {
    @apply transition-all duration-200 ease-linear;
  } 

  /* =====
  ==== POST EDITION HEADER
  ===== */
  #header-edition .input-post-title input {
    @apply read-only:bg-transparent read-only:border-transparent read-only:text-white text-2xl read-only:text-xl read-only:rounded-none read-only:px-0 py-0 h-10 font-bold leading-7 sm:truncate sm:text-2xl sm:read-only:text-3xl sm:tracking-tight;
  }
  #header-edition .input-post-status .inline-flex span.font-normal {
    @apply hidden;
  }
  #header-edition .input-post-status .block.mb-3 {
    @apply -mb-1;
  }
  #header-edition .input-post-status .inline-flex.cursor-pointer .peer:not(:checked) ~ div.bg-gray-200 {
    @apply bg-gray-300;
  }
  #header-edition .input-post-slug > label > span {
    @apply aria-[errormessage]:border-red-600 aria-[errormessage]:border-r-gray-200;
  }
  #header-edition .input-post-slug input {
    @apply h-10 rounded-l-none aria-[errormessage]:border-l-gray-300;
  }
  #header-edition .input-post-date .block.mb-3 > label {
    @apply h-10;
  }
  #header-edition .input-post-date .block.mb-3 > label > span {
    @apply aria-disabled:border-none aria-disabled:bg-transparent;
  }
  #header-edition .input-post-date .block.mb-3 > label > div input {
    @apply read-only:border-none read-only:bg-transparent read-only:text-gray-400;
  }
  #header-edition .input-post-date .block.mb-3 > p.text-red-600  {
    @apply pl-12;
  }
  
  /* #header-edition .input-post-date > div,
  #header-edition .input-post-slug label.flex > div,
  #header-edition .input-post-status .inline-flex > div {
    @apply mb-0;
  }
  #header-edition .input-post-slug input {
    @apply rounded-l-none;
  }
  #header-edition .input-post-title input {
    @apply bg-transparent border-transparent text-white text-2xl rounded-none px-0 py-0 h-[42px] font-bold border-b border-b-white leading-7 sm:truncate sm:text-3xl sm:tracking-tight;
  }
  #header-edition .input-post-date label input {
    @apply transition-none;
  }
  #header-edition .input-post-status .inline-flex > div.block {
    @apply mt-1;
  }
  #header-edition .input-post-status .inline-flex.cursor-pointer .peer:not(:checked) ~ div.bg-gray-200 {
    @apply bg-gray-300;
  }
  #header-edition .input-post-status .inline-flex span.ml-3.font-normal {
    @apply hidden;
  }
  #header-edition:not(.mode-active) .input-post-slug label > span,
  #header-edition:not(.mode-active) .input-post-slug label > div {
    @apply pr-0 rounded-none border-none bg-transparent
  }
  #header-edition:not(.mode-active) .input-post-date label > span,
  #header-edition:not(.mode-active) .input-post-date label input {
    @apply pr-0 rounded-none border-none bg-transparent text-gray-400 ;
  }
  #header-edition.mode-active .input-post-slug.with-error label, 
  #header-edition.mode-active .input-post-date.with-error label {
    @apply ring-2 ring-red-500 rounded-lg;
  }
  #header-edition.mode-active .input-post-title.with-error input {
    @apply !ring-2 ring-red-500 rounded-sm;
  } */

  /* =====
  ==== POSTS PAGINATION
  ===== */
  .posts-pagination,
  table tfoot.table-footer-pagination tr td ul {
    @apply inline-flex -space-x-px text-sm; 
  }
  .posts-pagination li a,
  table tfoot.table-footer-pagination tr td ul li a {
    @apply flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700; 
  }
  .posts-pagination li.break,
  table tfoot.table-footer-pagination tr td ul li.break,
  .posts-pagination li.selected,
  table tfoot.table-footer-pagination tr td ul li.selected {
    @apply cursor-default;
  }
  .posts-pagination li.disabled,
  table tfoot.table-footer-pagination tr td ul li.disabled {
    @apply cursor-default opacity-40;
  }
  .posts-pagination li.selected a,
  table tfoot.table-footer-pagination tr td ul li.selected a {
    @apply flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700;
  }
  .posts-pagination li:first-child a,
  table tfoot.table-footer-pagination tr td ul li:first-child a {
    @apply flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700;
  }
  .posts-pagination li:last-child a,
  table tfoot.table-footer-pagination tr td ul li:last-child a {
    @apply flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700;
  }
  


  /* #header-edition {
    @apply mb-0;
  }
  #header-edition .inputs-box .input-post-title input {
    @apply mb-0 px-0 rounded-none border border-transparent text-white text-2xl font-bold leading-7 bg-transparent sm:truncate sm:text-3xl sm:tracking-tight;
  }
  #header-edition.mode-active .inputs-box .input-post-title input {
    @apply border-b border-b-white
  }
  #header-edition .inputs-box .input-post-status {
    @apply mt-0
  }
  #header-edition .inputs-box .input-post-status > div {
    @apply mb-0;
  } */
  /* SLUG */
  /* #header-edition .inputs-box .input-post-slug label > span {
    @apply inline-flex items-center h-[42px] px-0 text-sm text-gray-400 bg-transparent border border-transparent rounded-none transition-all duration-300 ease-in-out;
  }
  #header-edition.mode-active .inputs-box .input-post-slug label > span {
    @apply px-3 bg-gray-200 border border-r-0 rounded-lg rounded-r-none  border-gray-300;
  } */
  /* #header-edition .inputs-box .input-post-slug label > span span {
    @apply ml-2;
  } */
  /* #header-edition .inputs-box .input-post-slug label input {
    @apply bg-transparent w-auto text-gray-400 rounded-none border -ml-0.5 border-transparent text-sm !outline-none block px-0 py-2.5 transition-all duration-300 ease-in-out !ring-0;
  }
  #header-edition.mode-active .inputs-box .input-post-slug label input {
    @apply ml-0 bg-gray-50 text-gray-900 border text-sm rounded-lg rounded-l-none px-2.5 border-gray-300 hover:border-gray-400 focus:border-blue-700;
  } */
  /* DATE */
  /* #header-edition:not(.mode-active) .inputs-box .input-post-date label > span {
    @apply px-0 text-sm text-gray-400 bg-transparent border border-transparent rounded-none transition-all duration-300 ease-in-out;
  }
  #header-edition:not(.mode-active) .inputs-box .input-post-date label input {
    @apply text-sm text-gray-400 bg-transparent border border-transparent rounded-none transition-all duration-300 ease-in-out;
  } */

  /* #header-edition-active .min-w-0 label {
    @apply mb-0;
  } */
  /* #header-edition-active .min-w-0 label input {
    @apply rounded-none p-2 bg-transparent dark:border-gray-300 text-gray-300 border-t-0 border-r-0 border-l-0 border-b-[1px] border-b-indigo-400 focus:ring-0;
  } */
  
  /* #header-edition-active .min-w-0 label input {
    @apply p-0 rounded-none bg-transparent outline-none ring-0 text-white border-0 border-b border-slate-400;
  } */
  /* #header-edition-active .min-w-0 > .max-w-xl label input {
    @apply text-2xl font-bold leading-7 sm:text-3xl sm:tracking-tight;
  } */
  /* #header-edition-active .min-w-0 > .flex label input {
    @apply text-base text-white;
  } */

  .media-modal-index {
    @apply !z-[1300];
   }

  /* =====
  ==== SCROLLBAR STYLE
  ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #b9c1ca #ffffff;
  }
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    @apply w-[7px];
    @apply h-[7px];
  }
  *::-webkit-scrollbar-track {
    @apply bg-white;
  }
  *::-webkit-scrollbar-thumb {
    @apply bg-[#b9c1ca] border-r-[10px] border-[3px] border-white;
  }
}

